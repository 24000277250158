import React, { Fragment } from 'react'

export default function DogIcon(props) {
    const {as:Component, wrapperClassName, className, ...rest} = props;

    return (
        <Component className={wrapperClassName ? wrapperClassName : null}>
            <svg role={`presentation`} className={className ? ['block w-full', className] : 'block w-full'} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	        viewBox="0 0 271 540" {...rest}>
                <g>
                    <path fill="#D0CECB" d="M92.3,408.8c0,0,58.9-42.5,89.1-39.1c30.1,3.5,20.6,65,30.7,75.4c10.1,10.4,51.5,16,58.2,37.8
                        c4.8,15.7-21.8,32.2-49.8,19.2c-28-13-61.6-3-76.4-4.8C129.3,495.7,77.5,488.4,92.3,408.8"/>
                    <path fill="#CE7438" d="M181.4,369.8c30.1,3.5,20.6,65,30.7,75.4c4.9,5,17,8.9,29.1,14.1c-1.3,0.4-2.6,0.9-3.8,1.3
                        c-10.5,3.4-22.3,5.7-31.9,0c-8.5-5-13.2-15.2-15.8-25.2c-2.7-10-3.8-20.6-7.5-30.2c-5.2-13.3-15-23.5-26.5-31
                        C165,370.8,174.1,368.9,181.4,369.8"/>
                    <path fill="#C5C3C2" d="M174,427.5c9.5-10.2,20.7-18.7,28.4-30.6c7.3-11.3,11-24.9,14.5-38.1c0.9-3.5,1.7-7.9-0.7-10.5
                        c-1.3-1.4-6-36.2-7.9-36.6c-31.2-6.8-59.6,20.7-90.7,13.9c-13.4-2.9-27.4-5.8-40.4-1.5c-11,3.7-20,12.2-27.3,21.7
                        c-12.5,16.3-20.7,36-26.2,56.3c-4.8,17.5-7.5,37.1-0.2,53.6c33.7,11.2,67.4,22.5,101.1,33.7c3.9,1.3,8.1,2.6,12,1.1
                        c5.7-2.2,7.9-9.4,9.9-15.6C152.1,457.2,161.6,440.8,174,427.5"/>
                    <path fill="#8E431F" d="M211.7,168.1c-24.1-0.4-48.6-3.1-72.4,2c-11.7,2.5-23.9,3.3-35.1,7.9c-6.3,2.6-12.3,5.9-18.1,9.5
                        c8.7-23,14.9-29.3,13.7-72.3c-0.9-34.5,105.3-39.3,103.2,0.6C201.6,142.2,206.8,156.1,211.7,168.1"/>
                    <path fill="#050504" d="M22.2,330c2.9-8.8,29.5-60.8,39.7-74.6c10.2-13.9,17.7-48.9,20.6-58c1.8-5.4,3.5-9.9,5.1-14
                        c6.4-7.4,13.5-14.1,21.5-19.5c25.9-17.3,58.2-17.6,88.6-17.4c2.3,0,4.8,0.1,7.2,0.2c2.8,13.7,7.8,22.5,11.2,32.3
                        c0.5,1.3,1.7,4.1,3.3,7.7c-8.6-3.6-108.6,159.2-111.5,169.7c-2.4,8.8-8.4,15.8-12.6,23.8c-8.2,15.8-8.8,34.6-11.4,52.4
                        c-1.6,11.1-4.5,22.8-12.2,30.3c-2.6,2.6-5.6,4.5-8.9,5.9c-11.3-3.8-11.3,14.6-29.5,29c-13.1-5.1-29.3,1.1-31.4-49.8
                        C0,397.3,19.3,338.8,22.2,330"/>
                    <path fill="#DDDAD7" d="M2.3,450c0,0,13.2-23.7,43.3-21.3c30.1,2.4,66.4,39.1,76.5,46.2c10.1,7.1,40.2,5.3,47.9,7.1
                        c7.7,1.8,20.3,6.9,20.3,18.3c0,11.4-10.4,29.2-38.4,20.3c-28-8.9-70.2-7.1-85-8.3C52.2,511.1-12.5,504.6,2.3,450"/>
                    <path fill="#CE7438" d="M51.7,467.5c19.1,15.9,38.3,31.9,58.4,46.3c-18.1-1.2-34.8-0.9-43-1.6c-14.8-1.2-79.5-7.7-64.7-62.3
                        c0,0,13.2-23.7,43.3-21.3c2.4,0.2,4.8,0.6,7.2,1.2c-0.9,0.1-1.7,0.3-2.5,0.5c-5.3,1.3-10.4,5-12.2,10.6c-1.6,5.1-0.1,10.9,2.7,15.4
                        C43.6,460.8,47.7,464.2,51.7,467.5"/>
                    <path fill="#EAE9E9" d="M94.9,411.1c12.3,2.6,28.4,0.9,37.3-5.6c0.6,18.6,2.4,35.8,2.6,38.5c0.5,5.7,4.7,59.4,5,67.6
                        c0.3,8.2,2.6,9,3.1,14.7c0.5,5.7-2.9,5.9-9.4,10.5c-6.5,4.5-20.9,4.2-24.6,0c-3.7-4.2-13.9-10.5-8.9-18.9c5-8.5-0.1-68.1-1.3-76.3
                        C98.2,437.8,96.6,424.8,94.9,411.1"/>
                    <path fill="#EAE9E9" d="M122,279c-9.9-14.5-15.1-32.7-14.5-50.8c0.3-8.4,1.9-16.9,6-23.9c4.1-7.1,11.1-12.5,18.8-13
                        c8.3-0.5,16.2,4.6,24.5,4.3c9.2-0.3,17.3-7.1,26.5-8.1c11.1-1.2,21.4,6.2,29.7,14.2c6.7,6.5,13.1,13.9,20.9,18.7
                        c0.2,0.5,0.3,0.9,0.4,1.3c2.6,8.5,18.3,46.1,15.2,63.3c-3.1,17.2-5,46.4-4.7,56.3c0.3,9.9,1.8,61.3-1.8,82
                        c-3.7,20.6,2.1,71.8,3.1,76.6c1,4.8,9.2,11.3,6.3,17.8c-2.9,6.5-6.3,13.6-17.8,13.3c-11.5-0.3-21.7,2.5-26.1-4.8
                        c-4.4-7.3-6.8-17.2-3.4-23.7c3.4-6.5,2.4-80.9,2.4-80.9s2.6-29.9-0.2-45.2v0c-0.3-17.9-2-35.8-4.9-53.5c-0.2-1.1-2.9-1.6-2.7-0.3
                        c2.5,15.2,4.1,30.6,4.7,46.1c-0.2-0.2-0.4-0.4-0.6-0.5c-7.8-5.7-57.5-24.6-66.9-10.7c-0.1,0.2-0.2,0.3-0.3,0.5
                        c0.3-5.1,0.9-10.6,1.8-17.3c1.3-9.7,4.4-19.5,2.8-29.1C139,298.7,129.2,289.4,122,279"/>
                    <path fill="#DC7F3D" d="M107.6,228.2c0.3-8.4,1.9-16.9,6-23.9c4.1-7.1,11.1-12.5,18.8-13c8.3-0.5,16.2,4.6,24.5,4.3
                        c9.2-0.3,17.3-7.1,26.5-8.1c11.1-1.2,21.4,6.2,29.7,14.2c6.7,6.5,13.1,13.9,20.9,18.7c0.2,0.5,0.3,0.9,0.4,1.3
                        c2.6,8.5,18.3,46.1,15.2,63.3c-1.5,8.4-2.7,19.6-3.6,30.1c-0.6-0.8-1.3-1.6-1.9-2.3c-4.6-4.9-10.3-9.3-12-16.1
                        c-2.1-8.3,2.7-16.6,4.2-25.1c2.4-14.5-5.2-28.5-12.6-40.9c-3.8-6.5-7.7-13.1-13.1-18c-5.4-5-12.6-8.2-19.6-6.8
                        c-7.4,1.5-13.3,7.8-20.6,10c-10.7,3.2-22.2-3-33-0.5c-12.9,2.9-21.1,17.7-22,31.9c-0.6,9.4,1.3,18.5,4.1,27.5
                        C111.2,261.1,107,244.6,107.6,228.2"/>
                    <path fill="#BC6428" d="M82.3,228.1c4.5-14.3,11.3-28.5,23.1-36.6c10-6.9,22.3-8.3,34.2-9.6c17.5-1.9,35.2-3.8,52.7-1.9
                        c9.2,1,18.5,3.2,27.1,6.8c4.5,10.1,12,26.4,14.6,33.7c-7.8-4.8-14.2-12.2-20.9-18.7c-8.3-8.1-18.6-15.4-29.7-14.2
                        c-9.2,1-17.3,7.8-26.5,8.1c-8.3,0.3-16.2-4.8-24.5-4.3c-7.7,0.5-14.7,5.9-18.8,13c-4.1,7.1-5.7,15.6-6,23.9
                        c-0.6,18,4.6,36.3,14.5,50.8c7.1,10.4,17,19.7,19.1,32.5c1.6,9.7-1.5,19.4-2.8,29.1c-0.9,6.7-1.5,12.2-1.8,17.3
                        c-4.5,7.6-5.1,28.3-4.4,47.5c0.3,8,0.8,15.7,1.2,22.2c-1.8,1.6-3.8,3-5.5,4.7c-5.1,5-7.4,13.2-5.7,20.4c0.9,3.9,2.9,7.8,1.8,11.7
                        c-0.8,2.8-3,4.8-5.1,6.6c-3.6,3.1-7.2,6.2-10.8,9.3c-2.3,2-4.6,4-6,6.7c-0.1,0.1-0.1,0.2-0.1,0.4c-0.6-19.3-2.6-41.3-3.3-46.1
                        c-0.6-3.7-2.1-16.7-3.8-30.4c-2.2-17.1-17.9-98-19.4-111.1C72.8,275.8,75.1,251.1,82.3,228.1"/>
                    <path fill="#050504" d="M67,512.3c-13.9-1.1-72.1-7-66.5-53.2c1.5,6.2,5.2,11.7,10.6,14.3c5.3,2.5,11.3,2.4,16.8,3.9
                        c11.1,3,19.7,11.9,28.6,19.6c6.9,5.9,14.2,11.2,21.9,15.9C73.9,512.6,69.9,512.5,67,512.3"/>
                    <path fill="#CE7438" d="M95.1,39.5c7-12.2,24.9-26.4,56.6-26.7c31.7-0.3,55.6,10.3,63.6,26.7c8,16.3,1.5,59.3-3.8,67.2
                        c-5.3,7.9-12.8,21-14.1,29.9c-1.3,9-2,22.6-14.6,31c-12.6,8.4-20.9,0.5-25.8,0c-4.9-0.5-20.5,5-24.8,3.9
                        c-4.3-1.2-13.8-12.9-14.6-18.3c-0.4-2.5-2-9-3.8-15.6c-2.1-7.6-4.6-15.3-6-17.3c-2.5-3.8-14-26.7-15.8-33.7
                        C89,74.9,88,51.7,95.1,39.5"/>
                    <path fill="#DC7F3D" d="M108,40.8c1.7-7,4-14,7.8-19.9c6.9-4.8,31.3-9.4,37.7-9.4c-1.4-2.4-3.9-4.1-6.6-4.4
                        c3.5-1.4,7.9,0.3,9.8,3.8c-0.2-3.8-1.2-7.6-2.9-10.9c2.8,3.3,4.8,7.3,6,11.6c0.9-3.1,2.8-5.8,5.4-7.4c-1.2,2.6-2.2,5.2-3,8
                        c3-2.2,6.9-2.7,10.3-1.4c-3.2-0.3-6.4,0.7-9.1,2.5c0.2,0,0.4,0,0.7,0c6.5-0.2,22.7,3.6,30.1,7.4c0,0,0,0,0,0
                        c1.3,0.6,2.6,1.2,3.9,1.9c3.2,5.9,5.3,12.5,6.4,19.2c1.8,11,1.2,22.3,0.4,33.5c-1.1,15.3-2.8,31.4-11.3,43.7
                        c-10.6,15.1-30.4,20.8-47.2,15.5c-16.8-5.3-30.1-20.4-36.5-38C103.5,79,103.6,59.1,108,40.8"/>
                    <path fill="#BC6428" d="M92,86.6c-1.2-4.6-2.1-11.1-2.3-17.9c1.9-8.8,4.6-17.4,8.5-25.5c-1.8,33.2,10.6,65.4,25.9,94.2
                        c3.9,7.4,8.2,14.9,14.8,19.6c6.5,4.7,14.5,6.2,22.2,6.5c6.2,0.2,12.7-0.3,17.9-4c8.3-5.9,10.7-17.5,13.6-27.8
                        c4.4-15.5,11.4-30,17.1-45c4.1-10.8,7.5-22,8.8-33.5c2,19.6-3,47.4-7.1,53.5c-5.3,7.9-12.8,21-14.1,29.9c-1.3,9-2,22.6-14.6,31
                        c-12.6,8.4-20.9,0.5-25.8,0c-4.9-0.5-20.5,5-24.8,3.9c-4.3-1.2-13.8-12.9-14.6-18.3c-0.8-5.4-7.3-29.1-9.8-32.9
                        C105.4,116.5,93.9,93.7,92,86.6"/>
                    <path fill="#EAE9E9" d="M111.6,129.9c0.2-1.3,0.5-2.6,0.9-3.9c1.8-6,5.2-11.2,8.6-16.4c4.5-6.8,9-13.8,15.2-18.9
                        c3.2-2.6,6.8-4.8,9.2-8.3c2.9-4.2,3.4-9.7,3.9-14.9c1.1-12.1,2.1-24.2,3.2-36.3c0.2-1.9,0.5-4,2-5c1.6-1,3.9,0,4.9,1.8
                        c1,1.7,1.1,3.9,1.2,6c0.4,14.6-0.2,30.2,6.5,42.9c6.5,12.4,18.9,20,26.1,32c3.5,5.8,5.6,12.3,6.9,19.1c-1.2,3.1-2.1,6-2.5,8.6
                        c-1.3,9-2,22.6-14.6,31c-12.6,8.4-20.9,0.5-25.8,0c-4.9-0.5-20.5,5-24.8,3.9c-4.3-1.2-13.8-12.9-14.6-18.3
                        C117.2,149.7,114.2,138.4,111.6,129.9"/>
                    <path fill="#D3D2D2" d="M125.1,148.8l4.8,2l10.4-2l9.5-7.6l6.1-1.7l7.1,2.9l6,5l6.4,3.5l9.1-1.1c0,0-0.4,8.2-28.4,9.7
                        C128.3,161,125.1,148.8,125.1,148.8"/>
                    <path fill="#924A21" d="M257.8,166.4c1.8-3.1,0.7-7.1,0.1-10.7c-1.9-13.2,5.1-25.8,6.6-39c1.2-10.4,2.6-23.8-4.7-30.7
                        c-11.6-11-40.7-64.9-60.6-62.5c6.4,15.2,15.5,32.9,15,49.6c-0.4,14.6-5.1,28.6-7.4,43c-2.3,14.4-1.9,30.5,6.6,41.9
                        c8.6,11.6,23.9,15.4,37.4,12.6C253.4,169.9,256.3,168.9,257.8,166.4"/>
                    <path fill="#A85826" d="M209.9,26.5c17.6,10.9,38.2,46.6,48.4,57.8c-1,5.3-2.5,10.6-3.7,15.8c-2.5,11-3.6,22.4-3.4,33.7
                        c0.1,6.5,0.3,14.1-4.3,18.3c-3.3,3.1-8.2,3.3-12.4,2c-9.8-3-16.8-14.4-15.7-25.3c0.8-7.4,4.6-13.9,7.1-20.8
                        c6.3-18.1,2.6-38.5-3.5-56.6C219.4,42.6,215.7,33.7,209.9,26.5"/>
                    <path fill="#924A21" d="M50.4,163.8c-1.7-3.2-0.5-7.1,0.1-10.7c2.2-13.1-4.5-25.9-5.7-39.2c-1-10.4-2-23.9,5.5-30.6
                        c11.8-10.6,42.3-63.7,62.2-60.7c-6.7,15-16.3,32.4-16.2,49.1c0,14.6,4.4,28.8,6.4,43.2c2,14.5,1.1,30.6-7.6,41.7
                        c-8.9,11.3-24.3,14.7-37.7,11.5C54.7,167.4,51.8,166.3,50.4,163.8"/>
                    <path fill="#A85826" d="M101.7,25.4C83.8,35.8,62.4,70.9,51.9,81.8c0.9,5.3,2.2,10.6,3.3,15.9c2.2,11.1,3,22.5,2.6,33.8
                        c-0.3,6.5-0.6,14.1,3.8,18.4c3.2,3.2,8.1,3.5,12.4,2.4c9.9-2.8,17.2-13.9,16.3-24.9c-0.6-7.4-4.3-14-6.6-21
                        C77.8,88.2,82,67.9,88.6,50C91.9,41.1,95.8,32.4,101.7,25.4"/>
                    <path fill="#FFFFFF" d="M132.6,115.9c1.1,0,2.2,0.1,3.3,0.4v0l39-1v0c1.2-0.3,2.4-0.5,3.7-0.5c9.1,0,16.5,8.2,16.5,18.2
                        c0,10.1-7.4,18.2-16.5,18.2c-5.1,0-9.7-2.6-12.7-6.7L161,141l-5.3-0.9l-11.4,5.7c-2.9,3.5-7.1,5.7-11.7,5.7
                        c-8.8,0-15.9-8-15.9-17.8C116.7,123.9,123.8,115.9,132.6,115.9"/>
                    <path fill="#EE6574" d="M162.4,178.2c-2.4,0.6-4.8,1.2-7.3,1.2c-2.5,0-5.1-0.6-6.9-2.3c-2-1.7-2.8-4.3-3.5-6.7
                        c-2-6.6-4.1-13.2-6.1-19.9c4-2.7,5.6-7.8,9.9-10c3.2-1.6,7.2-1,10.6,0.3c3.4,1.4,6.3,3.5,9.5,5.2c1,6.2,1.9,12.4,2.9,18.6
                        c0.5,3.1,0.9,6.5-0.9,9.1C168.9,176.4,165.5,177.4,162.4,178.2"/>
                    <path fill="#DF243E" d="M158.1,140.5c0.3,0.1,0.7,0.2,1,0.4c3.4,1.4,6.3,3.5,9.5,5.2c0.1,0.9,0.3,1.9,0.4,2.8
                        c-0.4,0.1-0.9,0.2-1.3,0.2c-3.5-0.1-6.9-1.8-9.7-3.9c-0.6-0.4-1.3-0.9-2-1.2c-0.7-0.3-1.6-0.4-2.3,0c0.9,6.6,5.7,25.1,4,21.6
                        c-1.6-3.5-5-21.3-5.8-21.6c-0.8-0.3-1.7-0.2-2.4,0.2c-0.7,0.4-1.3,1-1.7,1.7c-1.4,1.8-2.5,3.9-4.2,5.6c-1.1,1.1-2.6,2-4.1,2.3
                        c-0.3-1.1-0.7-2.2-1-3.3c3.1-2.1,4.8-5.7,7.5-8.2c1.9-1,3.9-1.8,6.1-2.1C154.1,140,156.1,140.1,158.1,140.5"/>
                    <path fill="#E54D5D" d="M147.5,170.3c1.3,1.9,2.8,3.9,5,4.8c2.2,0.9,4.7,0.7,7,0.4c1.8-0.2,3.7-0.5,5.4-1.1
                        c1.7-0.6,3.3-1.6,4.3-3.1c1.3-2,1.2-4.6,1.1-7c-0.1-2.6-0.1-5.2,0-7.8c0.4,2.8,0.9,5.6,1.3,8.4c0.5,3.1,0.9,6.5-0.9,9.1
                        c-1.8,2.5-5.1,3.5-8.2,4.3c-2.4,0.6-4.8,1.2-7.3,1.2c-2.5,0-5.1-0.6-6.9-2.3c-2-1.7-2.8-4.3-3.5-6.7c-1-3.2-2-6.3-2.9-9.5
                        C143.5,164.1,145.4,167.3,147.5,170.3"/>
                    <path fill="#F17786" d="M155.4,164.9c0.4,1.2,0.7,2.5,0.3,3.7c-0.5,1.3-1.9,2.1-3.3,2c-1.9-0.2-3.2-1.8-4.2-3.3
                        c-1.6-2.5-2.8-5.3-3.3-8.2c-0.4-2.9,0-5.9,1.5-8.4c0.6-1,1.5-1.9,2.6-2.3c0.4-0.2,1-0.2,1.3,0.1c0.1,0.2,0.2,0.3,0.3,0.5
                        C152.2,154.3,153.8,159.6,155.4,164.9"/>
                    <path fill="#F17786" d="M162.2,166c-1-0.5-1.7-1.5-2.2-2.5c-1.6-3.3-2.1-7-1.7-10.6c0.1-0.9,0.3-1.9,0.8-2.7
                        c0.5-0.8,1.4-1.4,2.4-1.4c1.1,0,2,0.8,2.8,1.6c2,2.3,2.9,5.3,3.3,8.3c0.3,2.2,0.3,4.5-1.1,6.2C165.5,166,163.6,166.7,162.2,166"/>
                    <path fill="#636363" d="M114,120.3c0.3-2.7,2.2,0.2,2,1.8c-1.2,9.9,2.9,24,12.5,26.9c10.8,3.3,16.3-8.6,25.2-11.1
                        c0-3.1,0-6.1,0-9.2c0-1.4,3.9-1.2,3.9,0.3c0,2.9,0,5.9,0,8.8c3,0.6,5.4,2.3,7.9,4.6c4.9,4.4,9.3,6.5,15.9,6.1
                        c11.7-0.7,12.8-14.3,12.1-24.1c-0.2-2.8,2.2-1.4,2.4,0.7c0.6,9.5,0.1,20.7-8.4,25.9c-4.7,2.8-12.2,2.3-16.8-0.5
                        c-5-3-8.5-8.4-14.5-8.9c-6.5,0.9-10,7.2-15.7,9.8c-6,2.7-12.3,2.7-17.5-1.6C115.7,144,112.9,129.6,114,120.3"/>
                    <path fill="#353535" d="M153,130.5c-5.6-1.2-9.4-6.8-11.7-12.4c-1.1-2.7-2-5.5-2-8.5c0-2.9,1-6,3.2-7.8c1.5-1.2,3.3-1.7,5.2-2.1
                        c5.2-1,10.5-1.1,15.7-0.1c3.2,0.6,6.7,1.9,8.2,5c1.2,2.6,0.7,5.7,0,8.5c-1.2,4.7-3.1,9.3-6.3,12.7
                        C162.1,129.3,157.4,131.4,153,130.5"/>
                    <path fill="#010101" d="M143,108.6c2.3-1.5,5.1,0.5,6.4,2.8c0.1,0.2,1.4,6.3,2,8.8c0.3,1.4,0.7,2.8,1.1,4.2
                        c0.2,0.7,0.5,1.4,0.8,2.1c1.3,2.8,2.8,0.2,3.3-0.9c0.9-2.2,1-5.2,1.4-7.6c0.4-2.4,0.5-5.3,1.6-7.5c1.7-3.3,4.9-4,7.8-2
                        c2.8,2,2,6.1-0.7,7.6c-1.9,1-4.1,0.3-5.5-1.2c-0.1,0.8-0.2,1.5-0.3,2.2c-0.5,2.8-0.8,5.7-1.4,8.6c-0.4,2-1.8,3.8-3.8,4
                        c-4.5,0.3-5.7-5.1-6.6-8.8c-0.4-1.8-0.6-3.7-1-5.6c-1.2,0.8-2.7,0.8-4.3,0.1C141,114.2,140.5,110.3,143,108.6"/>
                    <path fill="#505050" d="M158.4,106.8c-1.8,0.6-3.8,0.4-5.6,0c-1.9-0.4-3.7-1.1-5.6-1.4c-1.9-0.3-3.9-0.2-5.5,0.8
                        c0-1,0.6-1.8,1.3-2.4c0.7-0.6,1.6-0.9,2.4-1.2c6.9-2.4,14.4-2.9,21.3-0.4c0.9,0.3,1.8,0.7,2.6,1.4c0.7,0.7,1.2,1.8,1.1,2.8
                        c-2-1.2-4.2-2.4-6.5-2C161.8,104.8,160.2,106.2,158.4,106.8"/>
                    <path fill="#050504" d="M167.5,134.3c-0.4,0.6-1.1,0.9-1.8,0.7c-0.8-0.3-1.3-1.4-1-2.3c0.3-0.9,1.4-1.3,2.2-0.9
                        C167.6,132.3,167.9,133.5,167.5,134.3"/>
                    <path fill="#050504" d="M138.8,130.5c0.3,0.3,0.3,0.7,0.4,1.2c0,0.4,0,0.9-0.1,1.3c-0.2,0.4-0.5,0.7-0.9,0.8c-0.2,0-0.3,0-0.5,0
                        c-0.5-0.4-0.9-0.9-1-1.5c-0.1-0.6,0.1-1.3,0.5-1.7C137.7,130,138.4,130,138.8,130.5"/>
                    <path fill="#050504" d="M176.4,137.9c0.3,0.3,0.3,0.7,0.4,1.2c0,0.4,0,0.9-0.1,1.3c-0.2,0.4-0.5,0.7-0.9,0.8c-0.2,0-0.3,0-0.5,0
                        c-0.5-0.4-0.9-0.9-1-1.5c-0.1-0.6,0.1-1.3,0.5-1.7C175.2,137.4,176,137.4,176.4,137.9"/>
                    <path fill="#050504" d="M136.6,142.7c-0.8-0.2-1.5-0.8-1.7-1.6c-0.2-0.8,0.5-1.8,1.2-1.7c0.7,0.1,1.2,0.9,1.5,1.6
                        c0.2,0.5,0.3,1,0,1.4C137.5,142.7,137,142.8,136.6,142.7"/>
                    <path fill="#050504" d="M176.6,133.3c-0.8-0.2-1.5-0.8-1.7-1.6c-0.2-0.8,0.5-1.8,1.2-1.7c0.7,0.1,1.2,0.9,1.5,1.6
                        c0.2,0.5,0.3,1,0,1.4C177.5,133.4,177,133.4,176.6,133.3"/>
                    <path fill="#050504" d="M144.8,133.3c0.3-0.2,0.6-0.3,0.9-0.2c0.5,0.2,0.6,0.9,0.6,1.5c0,0.6,0,1.3-0.4,1.8
                        c-0.4,0.5-1.1,0.5-1.6,0.1c-0.5-0.4-0.7-1.1-0.5-1.7C143.9,134.2,144.3,133.6,144.8,133.3"/>
                    <path fill="#B66028" d="M199.3,84.9c-5.2,2.6-11.3,0.3-16.5-2.3c-3.6-1.9-7.3-4-10.1-7.1c-2.8-3.1-4.7-7.4-4.3-11.8
                        c0.6-5.3,4.5-9.5,8.3-12.9c2.4-2.2,5-4.3,8-5.5c3-1.2,6.4-1.5,9.2,0.1c3,1.6,4.9,4.8,6.5,7.9c2.8,5.2,5.4,10.6,5.8,16.6
                        C206.7,75.9,204.3,82.4,199.3,84.9"/>
                    <path fill="#B66028" d="M146.3,63.6c0.2,6.4-2,13-6.3,17.5c-4.3,4.5-10.6,6.7-16.4,5.4c-4.8-1.1-8.9-4.3-12.5-7.8
                        c-2.8-2.8-5.7-6.4-5.2-10.5c0.3-2.9,2.3-5.2,4.3-7.1c5.9-5.5,13.2-9.2,20.9-10.4c3.8-0.6,7.9-0.5,10.9,2
                        C145,55.3,146.2,59.6,146.3,63.6"/>
                    <path fill="#433028" d="M175.8,72.4c-0.9-1.6-1.2-3.4-1.2-5.2c-0.1-1.8,0-3.7,1.3-5.1c1.4-1.5,3.9-1.8,5.9-1.4
                        c7.7,1.7,10.8,8.4,10.9,10.1c0,1.7-0.5,3.5-1.8,4.6c-1.3,1.1-3,1.4-4.7,1.5C182.1,77.1,177.7,75.7,175.8,72.4"/>
                    <path fill="#FFFFFF" d="M185.2,75.3c1.6-0.1,3.3-0.3,4.5-1.2c1.3-0.9,1.8-2.3,1.7-3.6c0-1,0.6-0.2,0.6,1.1c0,1.3-0.5,2.7-1.7,3.6
                        c-1.2,0.9-2.9,1.1-4.5,1.2c-2.8,0.1-5.9-0.5-8-1.9c-0.7-0.5-1.1-1.4-0.3-0.9C179.6,74.9,182.5,75.4,185.2,75.3"/>
                    <path fill="#010101" d="M182.6,61.5c0.9,0.1,1.7,0.5,2.3,1.1c1.1,1.1,1.6,2.7,1.8,4.2c0.2,1.5,0.2,3.1-0.4,4.5
                        c-0.6,1.4-2.1,2.6-3.7,2.6c-1.4,0-2.6-0.8-3.4-1.9c-0.8-1.1-1.1-2.4-1.2-3.7c-0.2-1.6-0.1-3.2,0.7-4.6
                        C179.4,62.4,181,61.4,182.6,61.5"/>
                    <path fill="#FFFFFF" d="M179.5,65.2c0-0.5,0-1,0.3-1.5c0.3-0.4,0.8-0.7,1.4-0.6c0.1,0.9,0.2,1.7,0.3,2.6c0,0.3,0,0.5-0.1,0.8
                        c-0.3,0.4-1,0.4-1.4,0.1C179.6,66.2,179.5,65.7,179.5,65.2"/>
                    <path fill="#433028" d="M133.9,72.4c0.9-1.6,1.2-3.4,1.2-5.2c0.1-1.8,0-3.7-1.3-5.1c-1.4-1.5-3.9-1.8-5.9-1.4
                        c-7.7,1.7-10.8,8.4-10.9,10.1c0,1.7,0.5,3.5,1.8,4.6c1.3,1.1,3,1.4,4.7,1.5C127.6,77.1,132,75.7,133.9,72.4"/>
                    <path fill="#FFFFFF" d="M124.5,75.2c-1.6-0.1-3.3-0.3-4.5-1.2c-1.3-0.9-1.8-2.3-1.7-3.6c0-1-0.6-0.2-0.6,1.1c0,1.3,0.5,2.7,1.7,3.6
                        c1.2,0.9,2.9,1.1,4.5,1.2c2.8,0.1,5.9-0.5,8-1.9c0.7-0.5,1.1-1.4,0.3-0.9C130.1,74.8,127.2,75.3,124.5,75.2"/>
                    <path fill="#010101" d="M127.1,61.5c-0.9,0.1-1.7,0.5-2.3,1.1c-1.1,1.1-1.6,2.7-1.8,4.2c-0.2,1.5-0.2,3.1,0.4,4.5
                        c0.6,1.4,2.1,2.6,3.7,2.6c1.4,0,2.6-0.8,3.4-1.9c0.8-1.1,1.1-2.4,1.2-3.7c0.2-1.6,0.1-3.2-0.7-4.6
                        C130.3,62.4,128.7,61.3,127.1,61.5"/>
                    <path fill="#FFFFFF" d="M130.2,65.1c0-0.5,0-1-0.3-1.5c-0.3-0.4-0.8-0.7-1.4-0.6c-0.1,0.9-0.2,1.7-0.3,2.6c0,0.3,0,0.5,0.1,0.8
                        c0.3,0.4,1,0.4,1.4,0.1C130.1,66.1,130.2,65.6,130.2,65.1"/>
                </g>
                </svg>
        </Component>

    )
}

DogIcon.defaultProps = {
    as: Fragment
}