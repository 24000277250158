import * as React from "react"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
import { Link, useStaticQuery, graphql  } from "gatsby"
import {StaticImage} from 'gatsby-plugin-image'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import CoverImage from '../components/CoverImage'
import Section from '../components/Section'
import Hours from '../components/Hours'
import Button from '../components/Button'
import HeaderOffset from "../components/HeaderOffset"
import List from "../components/List"
import DogIcon from "../svg/DogIcon"

const AboutPage = () => {
	const aboutData = useStaticQuery(graphql`
	  query AboutPageQuery {
		site {
		  siteMetadata {
			title
			tagline
		  }
		}
	  }
	`)
	return (
	  <Layout>
	    {/* <Seo title="About" /> */}
		<CoverImage crop={true}>
				<StaticImage

					src={`../images/stock/casak9-bYWEc7rWXSs.jpg`}
					alt="view of Ottawa"
					quality={70}
					loading={`eager`}
					/>
			</CoverImage>

	    <Container size="small">
		<HeaderOffset>
			<PageHeader
		pageTitle={`About ${aboutData.site.siteMetadata.title}`}
		preheader={`${aboutData.site.siteMetadata.tagline}`}
		className="mx-auto text-center">
			<p className="mt-5 mb-0">
			Can't find the answer you're looking for? Don't hesitate to <Link to={`/contact`} className="text-primary-default hover:text-primary-darker border-b border-primary-default hover:border-primary-darker">contact us</Link>.
			</p>
		</PageHeader>
		</HeaderOffset>


	    </Container>
		{/* <Section style={{paddingTop: '0'}} size="small">
		<Section.Col className="w-full">
		<h2>What makes us different?</h2>
		<p>
		We treat your dogs like we treat our dogs – like family. 
		</p>
		<p>We offer in-home boarding – your little one can stay with us in our house, with our own dogs. (Space is limited for this option.)</p>
		<p>
		Our daily routine is based on meeting dogs’ needs: social, physical and mental. 
		</p>
		<List>
			<List.ListItem>From 7am to 9pm, at least 6 outdoor visits every day.</List.ListItem>
			<List.ListItem>Supervised outdoor play sessions.</List.ListItem>
		</List>
		<p>
		Introducing new dogs to the pack is structured to minimize stress, encourage gentle beginnings between pack members. 
		</p>
		<p>
		New clients are encouraged to come for a free, no-commitment meet and greet.
		</p>
		</Section.Col>
		</Section> */}

		<Section size="medium" gapOffset="md:-mx-7 lg:-mx-10" style={{paddingTop: '0'}}>
			<Section.Col className={`flex-trueauto md:px-7 lg:px-10`}>
			<h2>What makes us different?</h2>
			<p>
			We treat your dogs like we treat our dogs – like family. 
			</p>
			<p>We offer in-home boarding – your little one can stay with us in our house, with our own dogs. (Space is limited for this option.)</p>
			<p>
			Our daily routine is based on meeting dogs’ needs: social, physical and mental. 
			</p>
			<List>
				<List.ListItem>From 7am to 9pm, at least 6 outdoor visits every day.</List.ListItem>
				<List.ListItem>Supervised outdoor play sessions.</List.ListItem>
			</List>
			<p>
			Introducing new dogs to the pack is structured to minimize stress, encourage gentle beginnings between pack members. 
			</p>
			<p>
			New clients are encouraged to come for a free, no-commitment meet and greet.
			</p>
			</Section.Col>
			<Section.Col className={`md:block hidden lg:max-w-[38%] md:max-w-[35%] md:px-7 lg:px-10 lg:!pl-20 md:!pl-12`}>
					<DogIcon 
						as={`div`}
						wrapperClassName={`px-12 py-5 lg:py-7 lg:px-16 xl:py-10 xl:px-20 bg-gradient-to-tr from-slate-200 via-slate-300 to-slate-200 rounded-[80%] border-[2.5px] border-slate-300 shadow`}
					/>
			</Section.Col>
		</Section>

		<Section size="medium" gapOffset="md:-mx-7 lg:-mx-10">
		<Section.Col gaps="md:px-7 lg:px-10 md:max-w-1/2 md:flex-1/2">
		<StaticImage

			src={`../images/stock/casak9-BwYcH78rcpI.jpg`}
			alt="Ottawa"
			aspectRatio="1"
			quality={70}
			/>
		</Section.Col>
		<Section.Col gaps="md:px-7 lg:px-10 md:max-w-1/2 md:flex-1/2">
			<h2>Only 22 km from Ottawa International Airport</h2>
			<Hours heading={`Pickup & Dropoff Hours`} className="text-xs mt-3 leading-5" shortForm={false} />
			<div className="flex justify-start items-center space-x-5 mt-7">
				<Button to={`/contact`} variation="secondary">Contact Us</Button>
				<Button type={`external`} href={`https://casacanine.propetware.com/`} variation="outline">Book Appointment</Button>
			</div>
		</Section.Col>
		</Section>
	  </Layout>
	)
}

export const Head = () => <Seo2 title={`About`} />

export default AboutPage
